import { useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectToolFields, selectSelectedTool, setSelectedTool, selectToolInputs, selectToolOutputs, selectToolLoading } from '@/redux/ToolsSlice';
import { FetchToolFields, FetchToolInputs, FetchToolOutputs } from '@/redux/ToolsApis';
import { useTranslation } from 'react-i18next';

export const useCurrentTool = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedTool = useSelector(selectSelectedTool);
  const allToolFields = useSelector(selectToolFields);
  const allToolInputs = useSelector(selectToolInputs);
  const allToolOutputs = useSelector(selectToolOutputs);
  const loading = useSelector(selectToolLoading);
  const userId = localStorage.getItem('user_id') || '';
  
  const currentTool = useCallback(() => {
    return allToolFields?.find(tool => tool.name === selectedTool) || [];
  }, [allToolFields, selectedTool]);

  useEffect(() => {
    if (!userId) return;

    if (!allToolFields?.length && !loading.tools) {
      dispatch(FetchToolFields());
    }
    if (!allToolInputs?.length && !loading.inputs) {
      dispatch(FetchToolInputs());
    }
    if (!allToolOutputs?.length && !loading.outputs) {
      dispatch(FetchToolOutputs());
    }
    if (!selectedTool) {
      const path = window.location.pathname.split('/')[1];
      const tool = allToolFields?.find(tool => tool.name.split(' ')[0].toLowerCase() === path);
      
      if (tool) {
        dispatch(setSelectedTool(tool.name));
      }
    }
  }, [dispatch, allToolFields, selectedTool, allToolInputs, allToolOutputs, loading, userId]);

  const currentToolFields = useMemo(() => {
    return (currentTool().fields || []).map(field => {
      const fieldTranslation = getFieldTranslation(t, field.name);
      return {
        ...field,
        translation: fieldTranslation
      };
    });
  }, [currentTool, t]);

  const { single, plural } = getGenerationName(t, currentTool().generation_name || '');
  const toolId = currentTool().id || '';

  const toolInputs = allToolInputs?.find(tool => tool.id === toolId)?.inputs || [];
  const toolOutputs = allToolOutputs?.find(tool => tool.id === toolId)?.outputs || [];

  const getToolDescription = (tool_id) => {
    const tool = allToolFields?.find(tool => tool.id === tool_id);
    return tool ? `${selectedTool ? '' : tool.name+"'s "}${single}` : '';
  };

  return { currentToolFields, toolId, generation_name:single, gen_name_plural:plural, toolInputs, toolOutputs, toolName:selectedTool, getToolDescription };
};

const getGenerationName = (t, name) => {
  switch (name) {
    case 'Report':
      return {single: t("common.report"), plural: t("common.reports")};
    case 'Note':
      return {single: t("common.note"), plural: t("common.notes")};      
    default:
      return {single: name, plural: `${name}s`};
  }
}

const getFieldTranslation = (t, name) => {
  switch (name) {
    case "patient": 
      return {name: t("fields.patient"), enterText: t("fields.enterPatient")};
    case "doctor": 
      return {name: t("fields.doctor"), enterText: t("fields.enterDoctor")};
    case "location": 
      return {name: t("fields.location"), enterText: t("fields.enterLocation")};
    case "title": 
      return {name: t("fields.title"), enterText: t("fields.enterTitle")};
    default: 
      return {name: name, enterText: `Enter ${name}`};
  }
}