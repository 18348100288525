import React, { useState } from "react";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardTitle,
} from "@/components/shadcn-ui/card";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from "@/components/shadcn-ui/dialog";
import { Button } from "@/components/shadcn-ui/button";
import AddToken from "@/components/AddToken";
import { useSelector } from "react-redux";
import { selectPlans } from "@/redux/ProfileSlice";
import { Link } from "react-router-dom";
import { ProfileWrapper } from "../ui/CardWrapper";
import { useTranslation } from "react-i18next";

const Plans = () => {
  const { t } = useTranslation();
  const plans = useSelector(selectPlans) || [];
  const [addTokenOpen, setAddTokenOpen] = useState(false);

  const getPlanDescription = (plan) => {
    switch (plan.name) {
      case "Basic":
        return t("profile.plans.typesDescription.basic");
      case "Standard":
        return t("profile.plans.typesDescription.standard");
      case "Premium":
        return t("profile.plans.typesDescription.premium");
      default:
        return plan.description;
    }
  }

  return (
      <ProfileWrapper
        title={t("profile.plans.title")}
        description={t("profile.plans.description")}
        hasButton={false}
      >
        <>
          <CardContent className="flex gap-5 flex-col p-0 md:pt-0 md:pb-5">
            {plans?.map((plan) => (
              <Card key={plan.id} className="md:p-0 p-3 rounded-2xl">
                <div className="flex justify-between items-center md:px-6 md:py-4 flex-wrap gap-3 md:gap-0 md:border-b md:border-b-[#E4E4E7]">
                  <div>
                    <CardTitle className="text-[22px]">{plan.name}</CardTitle>
                    <CardDescription className="font-semibold text-[12px]">
                      {getPlanDescription(plan)}
                    </CardDescription>
                  </div>
                  <hr className="w-full flex md:hidden"/>
                  <div>
                    <Dialog className="hidden md:flex" open={addTokenOpen} onOpenChange={setAddTokenOpen}>
                      <DialogTrigger className="hidden md:flex">
                        <Button className="font-medium hidden md:flex">
                          {t("tokens.add")}
                        </Button>
                      </DialogTrigger>
                      <DialogContent className="rounded-3xl w-[600px] px-8 py-4">
                        <AddToken setIsOpen={setAddTokenOpen} />
                      </DialogContent>
                    </Dialog>
                  </div>
                </div>
                <CardContent className="flex justify-between items-center md:py-4 pt-3 md:px-6 p-0 flex-wrap gap-3 md:gap-0">
                  <div className="md:w-3/6 w-[45%] border-r border-r-[#E4E4E7]">
                    <CardTitle className="text-[20px]">
                      {plan.price_per_word}
                    </CardTitle>
                    <CardDescription className="text-[12px] font-semibold">
                      {t("tokens.perWord")}
                    </CardDescription>
                  </div>
                  <div className="md:w-3/6 md:px-5">
                    <CardTitle className="text-[20px] tracking-wider">
                      {parseInt(plan.minimum_amount, 10) === 0 ? t("profile.plans.noMinimum") : `${parseInt(plan.minimum_amount, 10).toLocaleString('en')} CHF`}
                    </CardTitle>
                    <CardDescription className="text-[12px] font-semibold">
                      {t("profile.plans.minimumAmount")}
                    </CardDescription>
                  </div>
                </CardContent>
                <div className="pt-3 md:pt-0">
                  <Dialog className="md:hidden flex w-full">
                    <DialogTrigger className="md:hidden flex w-full">
                      <Button className="font-medium w-full md:hidden flex">
                        {t("tokens.add")}
                      </Button>
                    </DialogTrigger>
                    <DialogContent className="rounded-3xl">
                      <AddToken />
                    </DialogContent>
                  </Dialog>
                </div>
              </Card>
            ))}
          </CardContent>
          <CardFooter className="block px-0 mt-3 md:mt-0">
            <p className="text-[14px] font-SuisseIntlLight font-normal">
              {t("profile.plans.enterpriseMessage")}{" "}
              <Link to='mailto:enrico@mpassist.ai' className="underline font-semibold cursor-pointer">{t("profile.plans.getInTouch")}</Link>{" "}
              {t("profile.plans.tailorMadeDeal")}
            </p>
          </CardFooter>
        </>
      </ProfileWrapper>
  );
};

export default Plans;
