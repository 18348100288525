import React, { useEffect, useRef, useState, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import NoReportFound from "../components/NoReportFound";
import Notification from "../components/Notification";
import StatusDropdown from "../components/StatusDropdown";
import Pagination from "../components/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { FetchReport, FetchBackendReports } from "../redux/ReportsApis";
import { setSelectedReport, selectFileUploading, selectReports, selectReportsLoading, selectRecordingDuration, selectRecordingStarted, selectReportId, setRecordingStarted, setReportId, setRecordingDuration, setIntegration } from "../redux/ReportsSlice";
import { Button} from "@/components/shadcn-ui/button";
import Loader from "../components/Loader";
import { MainCardWrapper } from "../components/ui/CardWrapper";
import closeCircle from "../images/x-circle.png";
import TextInput from "../components/ui/TextInput";
import { Input } from "@/components/shadcn-ui/input";
import { createRecordingLog, handleUpdateStatus } from "../utils/HelperFunctions";
import { FilterIcon, SearchIcon } from "../components/ui/SvgIcons";
import { pageSize } from "../utils/constants";
import { useCurrentTool } from "@/utils/ToolHelpers";
import { ArrowDownToLine, ArrowRightToLine, Check, Clock3, X } from "lucide-react";
import { AiOutlineExclamation } from "react-icons/ai";
import Report from "./Report";
import { formatTimestamp } from "../utils/date";
import AddReport from "./AddReport";
import CustomTable from "@/components/CustomTable";
import { Dialog, DialogContent, DialogTrigger, } from "@/components/shadcn-ui/dialog";
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from "@/components/shadcn-ui/dropdown-menu";
import { Drawer, DrawerClose, DrawerContent, DrawerDescription, DrawerHeader, DrawerTitle } from "@/components/shadcn-ui/drawer";
import { FetchUser } from "@/redux/UserApis";
import { selectFailed } from "@/redux/UserSlice";
import { handleLogout } from "@/utils/authSession";
import { DateInput } from "@/components/ui/DateInput";
import { useTranslation } from "react-i18next";
import { getTemplateType } from "@/lib/utils";

function debounce(func, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

function countFiltersApplied(filterValues) {
  let filters = 0;
  for (var key in filterValues) {
    if (filterValues[key] && filterValues[key] !== "") {
      filters++;
    }
  }
  return filters === 0 ? null : filters;
}

function Reports() {
  const selectedTabRef = useRef(localStorage.getItem("status") || "all");
  const [page, setPage] = useState(1);
  const [showNotification, setShowNotification] = useState(false);
  const reports = useSelector(selectReports);
  const loading = useSelector(selectReportsLoading);
  const [filtersModalOpen, setFiltersModalOpen] = useState(false);
  const [searchUserName, setSearchUserName] = useState("");
  const [searchIconClicked, setSearchIconClicked] = useState(false);
  const userToken = localStorage.getItem("user_session");
  const userId = localStorage.getItem("user_id");
  const report = useSelector((state) => state?.reportsSlice?.selectedReport);
  const fileUploading = useSelector(selectFileUploading);
  const [isAddOpen, setIsAddOpen] = useState(false);
  const { currentToolFields, generation_name, gen_name_plural, toolInputs, toolId, toolName } = useCurrentTool();
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const userFailed = useSelector(selectFailed);
  const recordingStarted = useSelector(selectRecordingStarted)
  const reocordingDuration = useSelector(selectRecordingDuration);
  const reportId = useSelector(selectReportId)
  const hideTypeColumn = ['Meeting AI']

  const { t } = useTranslation();

  const [tabs, setTabs] = useState([]);

  useEffect(() => {
    setTabs([
      {
        name: `${t('reports.tabs.all')} ${gen_name_plural}`,
        active: true,
        value: "all",
      },
      {
        name: t('reports.tabs.processing'),
        active: false,
        value: "processing",
      },
      {
        name: t('reports.tabs.generated'),
        active: false,
        value: "generated",
      },
      {
        name: t('reports.tabs.inProgress'),
        active: false,
        value: "in-progress",
      },
      {
        name: t('reports.tabs.completed'),
        active: false,
        value: "completed",
      },
      {
        name: t('reports.tabs.urgent'),
        active: false,
        value: "urgent",
      },
    ]);
  }, [gen_name_plural, t])

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const client = params.get("client");
    const patientId = params.get("patientId");
    const patientIdSystem = params.get("patientIdSystem");
    const firstName = params.get("firstName") || "";
    const lastName = params.get("lastName") || "";
    const fullName = `${firstName} ${lastName}`.trim();
    const template = params.get("template");

    if (client && patientId && patientIdSystem && template && toolInputs?.length) {
      setIsAddOpen(true);
      dispatch(setIntegration({ exists: true, client, patientId, patientIdSystem, template, fullName, path: location.pathname }));
    }
  }, [location.search, toolInputs, location, dispatch]);

  const toolFieldHeaders = currentToolFields.map((field) => ({
    Header: field.translation.name,
    accessor: field.name,
  }));

  const typeHeader = hideTypeColumn.includes(toolName) ? [] : [{
    Header: t("reports.table.type"),
    accessor: "template",
    Cell: ({ value }) => <span>{getTemplateType(value, t)}</span>
  }]

  useEffect(() => {
    if (!userId) {
      navigate("/");
    }
  }, [userId, navigate]);

  const EmptyFilterMode = ({ reset, filters }) => {
    return reset || Object.values(filters).every((value) => value === "");
  };

  const getInitialFilters = useCallback(() => {
    const savedFilters = localStorage.getItem(`${toolName} filters`);
    if (savedFilters && savedFilters !== "undefined") {
      return JSON.parse(savedFilters);
    }

    return currentToolFields?.reduce(
      (acc, field) => {
        acc[field.name] = "";
        return acc;
      },
      { comment: "", startDate: "", endDate: "" },
    );
  }, [currentToolFields, toolName]);

  const [activeFilters, setActiveFilters] = useState(getInitialFilters);
  const [filterValues, setFilterValues] = useState(getInitialFilters);

  const handleFilterSearch = useCallback(({ search, reset, resetSearchBar = false, searchUserName, dispatch, page }) => {
      const statuses = ["all", "processing", "in-progress", "completed", "generated", "urgent"];
      const filters = getInitialFilters();
      const userId = localStorage.getItem("user_id");

      if (!userId) return;

      search = !search && !resetSearchBar ? searchUserName : search;

      const fetchReports = (status) => {
        dispatch(FetchBackendReports({
            userId,
            status,
            page,
            pageSize,
            search,
            filters: !EmptyFilterMode({ reset, filters }) ? filters : undefined,
            toolId,
          })
        );
      };

      statuses.forEach(fetchReports);
      if (!EmptyFilterMode({ reset, filters })) setActiveFilters({ ...filters });
    },
    [getInitialFilters, toolId]
  );

  useEffect(() => {
    if(currentToolFields.length === 0) return;
    handleFilterSearch({ search: searchUserName, dispatch, page });
    // eslint-disable-next-line
  }, [page, dispatch, currentToolFields]);

  useEffect(() => {
    if (!!userId && userFailed) {
      handleLogout();
    }
  }, [userFailed, userId]);

  useEffect(() => {
    const userId = localStorage.getItem("user_id");
    if (!userId || userId === "undefined") {
      return;
    }
    dispatch(FetchUser({ userId, toolId }));
  }, [userToken, toolId, dispatch]);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 768px)");
    const handleScreenChange = (e) => {
      if (e.matches) {
        setSearchIconClicked(false);
      }
    };
    mediaQuery.addEventListener("change", handleScreenChange);
    return () => mediaQuery.removeEventListener("change", handleScreenChange);
  }, []);

  useEffect(() => {
    document.body.style.overflow = isDrawerOpen ? "hidden" : "auto";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isDrawerOpen]);  

  const handleTabChange = async (tabType) => {
    let updatedTabs = [];
    tabs?.forEach((tab) => {
      if (tab.name === tabType) {
        localStorage.setItem("status", tab.value);
        selectedTabRef.current = tab.value;
        updatedTabs.push({
          name: tab.name,
          active: true,
          value: tab.value,
        });
      } else {
        updatedTabs.push({
          name: tab.name,
          active: false,
          value: tab.value,
        });
      }
    });
    setTabs(updatedTabs);
    setPage(1);
  };

  const handleRetry = async ({ id }) => {
    await handleUpdateStatus({ documentId: id, status: "retry" });
    dispatch(FetchReport({ id, currentToolFields }));
  };

  const getReportsComponent = () => {
    const selectedTabReports = reports[selectedTabRef.current];
    const startRange = (page - 1) * pageSize + 1;
    const totalCount = selectedTabReports?.totalCount || 0;
    const endRange = Math.min(page * pageSize, totalCount);

    const columns = [
      {
        Header: "ID",
        accessor: "row_number",
      },
      {
        Header: t("reports.table.status"),
        accessor: "status",
        Cell: ({ value }) => {
          const statusIcons = {
            "generated": <ArrowDownToLine className="text-[#F17B2C] h-4 w-4"/>,
            "failed": <X className="text-[#EF4343] h-4 w-4"/>,
            "completed": <Check className="text-[#38C793] h-4 w-4"/>,
            "in-progress": <ArrowRightToLine className="text-blue-500 h-4 w-4"/>,
            "urgent": <AiOutlineExclamation className="text-[#DF1C41] h-4 w-4"/>,
            "processing": <Clock3 className="text-[#868C98] h-4 w-4"/>
          };
      
          const statusToTranslationKey = {
            "generated": "reports.tabs.generated",
            "failed": "reports.tabs.failed", 
            "completed": "reports.tabs.completed",
            "in-progress": "reports.tabs.inProgress",
            "urgent": "reports.tabs.urgent",
            "processing": "reports.tabs.processing"
          };
      
          const translationKey = statusToTranslationKey[value] || value;
      
          return (
            <div className={`border border-[#e3e4e9] flex justify-center gap-1 w-fit cursor-pointer bg-white rounded-md p-1 ${value === "failed" && 'cursor-not-allowed'}`}>
              {statusIcons[value]}
              <span className="font-SuisseIntlLight font-semibold text-xs text-[#525866]">
                {t(translationKey)}
              </span>
            </div>
          );
        },
      },
      ...toolFieldHeaders,
      ...typeHeader,
      {
        Header: t("reports.table.date"),
        accessor: "created_at",
        Cell: ({ value }) => formatTimestamp(value, "m/d/y/h"),
      },
    ];

    const handleReportDrawer = (rowNumber) => {
      // Find the matching report by row_number
      const report = selectedTabReports?.reports?.find(
        (report) => report.row_number === rowNumber
      );

      if (!report || !report.report_fetched) {
        console.log("Report not found");
        return;
      }

      // Check if the status is "processing"
      if (report?.status === "processing") {
        setShowNotification(true);
        setTimeout(() => {
          setShowNotification(false);
        }, 3000);
        return;
      }

      let updatedReport = { ...report };
      // If the report exists and contains placeholders, replace them
      if (report && report.report) {
        currentToolFields.forEach((field) => {
          const placeholder = `placeholder_${field.name}`;
          updatedReport.report = updatedReport.report?.replace(
            new RegExp(placeholder, "g"),
            (report.fields && report.fields[field.name]) || report[field.name] || ""
          );
        });
      }

      setDrawerOpen(true)
      dispatch(setSelectedReport(updatedReport));
    };

    if (selectedTabReports?.reports?.length > 0 && generation_name) {
      return (
        <div className="w-full">
           <CustomTable
              selectedTabReports={selectedTabReports?.reports}
              columns={columns}
              onClickFunction={(rowNumber) => handleReportDrawer(rowNumber)}
              handleRetry={handleRetry}
            />
          <Drawer direction="right" open={isDrawerOpen} onOpenChange={setDrawerOpen}>
           
            <DrawerContent className="md:max-w-[604px] p-6 ml-auto h-full bg-white overflow-y-scroll overflow-x-hidden scroll-smooth">
              <DrawerHeader className="relative flex justify-between items-center p-0">
                <div>
                  <DrawerTitle
                    className="text-black text-[20px] font-medium space-x-1 cursor-pointer capitalize text-left"
                    onClick={() => navigate(`${location.pathname}/${report.row_number}`)}
                  >
                    {report.fields?.[currentToolFields?.[0].name]}
                  </DrawerTitle>
                  <DrawerDescription>
                    {generation_name} {report.row_number}, {formatTimestamp(report.created_at, "d/m/y")}
                  </DrawerDescription>
                </div>
                <DrawerClose className="">
                  <Button variant="ghost" size="icon" className="h-6 w-6">
                    <X className="h-6 w-6 text-black" />
                    <span className="sr-only">Close</span>
                  </Button>
                </DrawerClose>
              </DrawerHeader>
              <div className="">
                <Report isDrawer={true} />
              </div>
            </DrawerContent>
          </Drawer>
          {selectedTabReports?.totalCount > pageSize && (
            <div
              className={`flex flex-col-reverse gap-1 sm:flex-row  justify-between items-center md:items-center w-full ${
                selectedTabReports.totalCount > pageSize ? "pt-3" : "mt-4"
              }`}
            >
              <div className="text-[#868C98] text-sm sm:items-center overflow-hidden">
                <p className=" mr-2 whitespace-nowrap overflow-hidden font-SuisseIntlLight font-medium">
                  {t('reports.pagination', {
                    startRange,
                    endRange,
                    totalCount,
                    generation_name: gen_name_plural
                  })}
                </p>
              </div>
              <Pagination
                total={selectedTabReports.totalCount}
                totalPages={Math.ceil(selectedTabReports?.totalCount / 50)}
                pageSize={pageSize}
                page={page}
                setPage={setPage}
                className="mt-2 sm:mt-0 flex justify-center"
              />{" "}
            </div>
          )}
          {!(selectedTabReports?.totalCount > pageSize) && (
            <div
              className={`flex-col sm:flex-row flex w-full justify-between items-center  md:items-center ${
                selectedTabReports.totalCount > pageSize ? "pt-3" : "mt-4"
              }`}
            >
            <div className="text-[#868C98] text-sm sm:items-center overflow-hidden float-left">
              <p className="mr-2 whitespace-nowrap font-SuisseIntlLight font-medium text-[#71717A] text-[13.56px]">
                {t('reports.pagination', {
                  startRange,
                  endRange,
                  totalCount,
                  generation_name: gen_name_plural
                })}
              </p>
            </div>
            </div>
          )}
        </div>
      );
    } else {
      return <div className="my-12">
        <NoReportFound generation_name={gen_name_plural}/>
      </div>;
    }
  };

  // eslint-disable-next-line
  const debouncedHandleSearchUserName = useCallback(
    debounce(({ search }) => {
      handleFilterSearch({ search, dispatch, page: 1 });
      setPage(1);
    }, 500),
    [currentToolFields]
  );

  const handleFilterInputChange = (e) => {
    const { name, value } = e.target;

    setFilterValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSearchIconClicked = (clicked) => {
    setSearchIconClicked(clicked);
  };

  const resetFilter = () => {
    if (!currentToolFields) return;
    try {
      setSearchUserName("");

      const filters = currentToolFields?.reduce(
        (acc, field) => ({
          ...acc,
          [field.name]: "",
        }),
        { comment: "", startDate: "", endDate: "" }
      );
    
      requestAnimationFrame(() => {
        setFilterValues(filters);
        setActiveFilters(filters);
        localStorage.removeItem(`${toolName} filters`);
        handleFilterSearch({ search: "", reset: true, dispatch, page: 1 });
        setPage(1);
      });
    } catch (error) {
      console.error("Error resetting filters:", error);
    }
  };

  const handleSearchUserName = (e) => {
    const { value } = e.target;
    setSearchUserName(value);
    debouncedHandleSearchUserName({ search: value });
  };

  const handleCloseIcon = () => {
    setSearchUserName("");
    setSearchIconClicked(false);
    searchUserName && handleFilterSearch({
      search: "",
      reset: false,
      resetSearchBar: true,
      searchUserName,
      dispatch,
      page: 1,
    });
    setPage(1);
  };
  const getTextInput = () => {
    return (
      <TextInput
        searchClicked={searchIconClicked}
        name="search_name"
        placeholder={`${t("actions.search")}...`}
        value={searchUserName}
        onChange={handleSearchUserName}
        type="text"
        className='w-fit'
        useWidthOnMobile={false}
        {...(!searchUserName
          ? {}
          : {
              postIcon: (
                <img
                  src={closeCircle}
                  alt=""
                  height={16}
                  width={16}
                  className="cursor-pointer hidden md:block"
                  onClick={handleCloseIcon}
                />
              ),
            })}
      />
    );
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    localStorage.setItem(`${toolName} filters`, JSON.stringify(filterValues));
    handleFilterSearch({ dispatch, page: 1 });
    setPage(1);
  };

  const getFilterModel = () => {
    return (
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="bg-white flex flex-col max-w-[315px] p-4 sm:rounded-[15px] w-full sm:max-h-min sm:min-h-min"
      >
        <div className="flex w-full">
          <form
            onSubmit={handleFormSubmit}
            className="flex flex-col gap-[10px] w-full h-full sm:justify-center"
          >
            <div className="grid grid-cols-2 w-full gap-[10px] justify-center items-center">
              <DateInput
                name="startDate"
                value={filterValues.startDate}
                onChange={handleFilterInputChange}
                placeholder={t("reports.filters.dateFrom")}
              />
              <DateInput
                name="endDate"
                value={filterValues.endDate}
                onChange={handleFilterInputChange}
                placeholder={t("reports.filters.dateTo")}
              />
            </div>
            <div className="flex flex-col gap-[10px] w-full">
              {currentToolFields?.map((field) => (
                <Input
                  key={field.name}
                  name={field.name}
                  placeholder={field.translation.name}
                  type="text"
                  value={filterValues[field.name]}
                  onChange={handleFilterInputChange}
                />
              ))}
              <Input
                textArea={true}
                name={"comment"}
                placeholder={t("common.comment")}
                type="text"
                value={filterValues.comment}
                onChange={handleFilterInputChange}
              />
            </div>
            <div className="flex flex-col justify-center gap-[10px] w-full">
              <Button
                type="submit"
                className="font-SuisseIntlLight font-medium"
              >
                 {t("reports.filters.apply")}
              </Button>
              {Object.values(activeFilters).some((value) => value) && (
                <Button
                  type="button"
                  variant="outline"
                  className="font-SuisseIntlLight font-semibold text-black"
                  onClick={resetFilter}
                >
                  {t("reports.filters.reset")}
                </Button>
              )}
            </div>
          </form>
        </div>
      </div>
    );
  };
  const count = countFiltersApplied(activeFilters);

  const handleAddDialog = (open) => {
    if (fileUploading) return;
    setIsAddOpen(open);
    if (!open && recordingStarted) {
      createRecordingLog({ type:'discard', duration: reocordingDuration, reportId, createdAt: new Date().toISOString() });
      dispatch(setRecordingStarted(false))
      dispatch(setRecordingDuration(0))
      dispatch(setReportId(null))
    }
  }

  return (
    <div className="mb-5">
      <div className="flex justify-end items-center md:hidden mt-3">
          <Dialog open={isAddOpen} onOpenChange={handleAddDialog}>
            <DialogTrigger>
              <Button disabled={!toolInputs.length}>
                <div className="text-[14px]"><span className="text-[16px] mr-1">+</span> {t('reports.add.text', { type: generation_name })}</div>
              </Button>
            </DialogTrigger>
            <DialogContent addingReport fileUploading={fileUploading} className='md:hidden'>
              <AddReport/>
            </DialogContent>
          </Dialog>
      </div>
      <MainCardWrapper className='p-6 sm:pb-3'>
        <div className="flex justify-between items-center pb-[24px]">
          <div className="min-w-[132px]">
            <StatusDropdown
              tabs={tabs}
              selected={selectedTabRef.current}
              handler={handleTabChange}
              reports={true}
              showReportCount={true}
            />
          </div>
          <div className="mt-[4px] flex items-center gap-3 sm:mt-0 w-full justify-end">
            {!searchIconClicked ? (
                <div className="flex justify-end gap-3">
                  <div className="hidden relative md:left-3 md:flex h-[42px] ease-in-out transition-all">
                    {getTextInput()}
                  </div>
                  <div>
                    <button
                      className="flex ml-[10px] sm:ml-0 md:hidden items-center gap-[10px] text-[14px] justify-start border-[#E5E7EC] text-[#525866] border-[1px] rounded-[10px] py-[10px] px-[12px] w-[44px] h-[42px]"
                      onClick={() => {
                        handleSearchIconClicked(true);
                      }}
                    >
                      <SearchIcon />
                    </button>
                  </div>
                  <DropdownMenu isOpen={filtersModalOpen} onOpenChange={setFiltersModalOpen}>
                    <DropdownMenuTrigger>
                      <Button
                        className={`hidden md:flex h-full text-[14px] rounded-[10px] ${count && 'font-semibold'} text-center justify-center items-center`}
                        variant="outline"
                      >
                        <span className="mt-[1px]">{t("reports.filters.text")} {count ? '('+ count + ')' : ''}</span>
                      </Button>
                      <Button
                        variant="outline"
                        className={`block h-full relative md:hidden items-center gap-[10px] ${filtersModalOpen && 'bg-slate-100'}`}
                      >
                        <div>
                          {count ? (
                            <span class="circle mobile right-0 bottom-0 absolute">
                              <span>{count}</span>
                            </span>
                          ) : null}
                          <FilterIcon />
                        </div>
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align='end' className='rounded-[14px] mt-[6px]'>
                      {getFilterModel()}
                    </DropdownMenuContent>
                  </DropdownMenu>
                </div>
            ) : (
              <div className="flex gap-3">
                {getTextInput()}
                <button
                  className=" border-[#E5E7EC] border-[1px] rounded-[10px] px-2 h-[42px]"
                  onClick={handleCloseIcon}
                >
                  <X className="text-[#525866]" />
                </button>
              </div>
            )}
            <Dialog 
              className="hidden md:flex" 
              open={isAddOpen} 
              onOpenChange={handleAddDialog}
            >
              <DialogTrigger className="hidden md:flex">
                <Button disabled={!toolInputs.length}>
                  <div className="text-[14px]"><span className="text-[20px] mr-1">+</span> {t('reports.add.text', { type: generation_name })}</div>
                </Button>
              </DialogTrigger>
              <DialogContent
                className="md:h-fit h-full shadow-none rounded-[25px]"
                addingReport
                fileUploading={fileUploading}
              >
                <AddReport/>
              </DialogContent>
            </Dialog>
          </div>
        </div>
          <div className={`sm:min-w-full flex items-center ${
            (loading[selectedTabRef.current] || !generation_name) ? 'justify-center' : ''
          }`}>
            {(loading[selectedTabRef.current] || !generation_name) ? (
              <div className="w-full h-full min-h-[30vh] flex justify-center items-center">
                <Loader />
              </div>
            ) : (
              <div className="w-full h-full items-center flex flex-col self-start">
                {getReportsComponent()}
              </div>
            )}
          </div>
        {showNotification && (
          <Notification
            text={"This report is being generated"}
            color={"bg-black"}
          />
        )}
      </MainCardWrapper>
    </div>
  );
}

export default Reports;
