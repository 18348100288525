import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import TextInput from './ui/TextInput';
import DropDown from './ui/DropDown';
import { Button } from './shadcn-ui/button';
import { selectAllTemplates, selectIntegration } from '../redux/ReportsSlice';
import { getTemplates as getUserTemplates } from '../redux/UserSlice';
import { toast } from 'react-toastify';
import RecordingPopUp from './RecordingPopUp';
import { useCurrentTool } from '@/utils/ToolHelpers';
import { Checkbox } from './shadcn-ui/checkbox';
import { checkIfOnline } from '@/utils/HelperFunctions';
import Loader from './Loader';
import { useTranslation } from 'react-i18next';
import { generalLanguages, modelLangugaes } from '@/utils/constants';
import { getTemplateType } from '@/lib/utils';

function capitalize(string) {
    const words = string.trim().split(/[\s_]+/);
    return words.map((word) => !!word ? (word[0].toUpperCase() + word.substring(1)) : '').join(' ')
}  

export default function RecordForm({ GenerateReport, recordedAudioBlob, handleGenerateReport, UploadNewRecording, HandleResetState }) {   
    const { t } = useTranslation();
    const templates = useSelector(selectAllTemplates);
    const userTemplates = useSelector(getUserTemplates);
    const [loading, setLoading] = useState(false);
    const { currentToolFields, generation_name, toolName } = useCurrentTool();
    const integration = useSelector(selectIntegration);
    const nameExists = integration.exists && integration.fullName.trim() !== '';
    const isScribe = toolName === 'Scribe AI';

    const toolFields = currentToolFields.reduce((acc, field) => {
      acc[field.name] = field.position === 1 && nameExists ? integration.fullName : localStorage.getItem(field.name) || '';
      return acc;
    }, {});

    const [toolFieldState, setToolFieldState] = useState(toolFields);

    const [formState, setFormState] = useState({
        audioLanguage: localStorage.getItem('audio_language') || 'de',
        reportLanguage: localStorage.getItem('report_language') || 'german (switzerland)',
        comment: '',
        template: 'select_template',
        urgent: false,
        enableTextModules: false,
    });

    const [uploadNew, setUploadNew] = useState(false);

    useEffect(() => {
        setFormState((prev) => ({ ...prev, recordedAudioBlob }));
    }, [recordedAudioBlob]);

    const handleToolFieldChange = (e) => {
        const { name, value } = e.target;
        setToolFieldState((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      };
      
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormState((prev) => ({ ...prev, [name]: value }));
    };
    
    const handleDropDownChange = (value) => {
        setFormState((prev)=>({...prev, [value.name]:value.value}))
    }

    const handleGenerateNewReport = async (e) => {
        e.preventDefault();

        setLoading(true);
        const isOnline = await checkIfOnline();
        setLoading(false);
        if(!isOnline){
            toast.error(t('form.form.errors.networkError'));
            return;
        }
      
        const missingToolFields = currentToolFields.filter(
          (field) => !toolFieldState[field.name]
        ).length;
      
        const { audioLanguage, reportLanguage, template, urgent, enableTextModules } = formState;
      
        if (missingToolFields > 0 || !audioLanguage || !reportLanguage || !recordedAudioBlob) {
          toast.error(t('form.form.errors.fillAllFields'));
          return;
        }
      
        const isCustomTemplate = userTemplates.includes(template);
        const toolFieldsData = currentToolFields.map((field) => ({
            id: field.id || '',
            name: field.name,
            value: toolFieldState[field.name],
        }));
      
        const data = {
          toolFieldsData,
          ...formState,
          uses_custom_template: isCustomTemplate,
          recordedAudioBlob,
          urgent,
          enableTextModules,
        };

        try {
            GenerateReport('generating');
            await handleGenerateReport(data);
        } catch (error) {
            console.error("Error generating report: ", error);
            toast.error(t('form.errors.generationFailed', { type: generation_name.toLowerCase() }));
        }
    };

    const getTemplates = () => {
        let systemTemplates = [...templates].sort((a, b) => {
            if (a.name === "no template") return -1;
            if (b.name === "no template") return 1;
            return a.name.localeCompare(b.name);
        });
        const additionalUserTemplates = userTemplates?.length ? userTemplates.map(template => ({ name: template, value: template }))?.sort((a, b) => a?.name?.localeCompare(b?.name)) : [];
        additionalUserTemplates.map(template => template.name = `Custom - ${template.name}`);
        return [...systemTemplates, ...additionalUserTemplates];
    };

    const inputLanguages = modelLangugaes.map(lang => ({ name: t(`languages.${lang}`), value: lang })).sort((a, b) => a.name.localeCompare(b.name));
    const outputLanguages = generalLanguages.map(lang => ({ name: t(`languages.${lang}`), value: lang.toLowerCase() })).sort((a, b) => a.name.localeCompare(b.name));
    const templateOptions = getTemplates().map((template) => {
        return {
            name: getTemplateType(capitalize(template.name), t),
            value: template.value,
        }
    })

    useEffect(() => {
        if (integration.exists) {
            const selectedTemplate = templates.find((item) => {
                const templateValue = item.value.replace(/_template/gi, '').toLowerCase(); //remove all _template occurances
                return templateValue === integration.template.toLowerCase();
            });

            if (selectedTemplate) {
                setFormState((prev) => ({ ...prev, template: selectedTemplate.value }));
            }
        }
    }, [integration, templates]);

    return (
        <div className='w-full'>
            <form className='text-left w-full' onSubmit={handleGenerateNewReport}>
                <div className='mt-[10px] grid grid-cols-2 md:gap-x-[20px] gap-3'>
                {currentToolFields.map((field) => (
                    <TextInput
                        key={field.name}
                        required
                        name={field.name}
                        label={field.translation.name}
                        placeholder={field.translation.enterText}
                        type={field.type || 'text'}
                        value={toolFieldState[field.name]}
                        className={`${field.position === 3 || currentToolFields.length === 1 ? 'col-span-2' : 'col-span-2 md:col-span-1'}`}
                        onChange={handleToolFieldChange}
                    />
                ))}
                    
                </div>
                <div className='mt-[10px] flex flex-col sm:flex-row gap-[20px]'>
                    <div className='flex-1'>
                        <DropDown
                            label={t('form.inputLanguage')}
                            options={inputLanguages}
                            handleOnChange={(value) => { handleDropDownChange({ name: 'audioLanguage', value: value }) }}
                            selectedValue={formState.audioLanguage}
                            selectText={t('form.selectLanguage')}
                        />
                    </div>
                    <div className='flex-1'>
                        <DropDown
                            label={t('form.outputLanguage')}
                            options={outputLanguages}
                            handleOnChange={(value) => { handleDropDownChange({ name: 'reportLanguage', value: value }) }}
                            selectedValue={formState.reportLanguage}
                            selectText={t('form.selectLanguage')}
                        />
                    </div>
                </div>
                {templateOptions.length > 0 && <div className='mt-[10px]'>
                    <DropDown
                        label={t('form.template')}
                        options={templateOptions}
                        handleOnChange={(value) => { handleDropDownChange({ name: 'template', value: value }) }}
                        selectedValue={formState.template === 'select_template' ? null : formState.template}
                        selectText={t('form.selectTemplate')}
                    />
                </div>}
                <div className='mt-[10px] flex flex-col text-sm gap-1 w-full'>
                    <label htmlFor="comment" className="font-semibold font-SuisseIntlLight text-[14px]">{t('common.comment')}</label>
                    <textarea
                        id="comment"
                        name="comment"
                        className="rounded-[10px] inputFieldShadow text-[14px] font-SuisseIntlLight font-normal text-[#868C98] border-[1px] py-2.5 px-4 border-[#E2E4E9] hover:border-[#CED0D5] focus:border-[#A6A8AD] focus:ring-0 focus:outline-none w-full resize-none"
                        rows="3"
                        maxLength='5000'
                        placeholder={t('form.addCommentOptional')}
                        value={formState.comment}
                        onChange={handleChange}
                    />
                </div>
                <div className={`grid ${isScribe ? 'grid-cols-2' : 'grid-cols-1'} gap-6 mt-[14px]`}>
                    <div className="flex text-sm gap-2 items-center">
                        <Checkbox
                            id="urgent"
                            className={`border-[2.3px] border-slate-200 rounded-[4px] ${formState.urgent ? 'border-0' : 'bg-white'}`}
                            checked={formState.urgent}
                            onCheckedChange={(checked) => setFormState((prev) => ({ ...prev, urgent: checked }))}
                        />
                        <label htmlFor="urgent" className="font-medium text-[14px] flex cursor-pointer">
                            <span className="font-semibold font-SuisseIntlLight">{t('form.markAsUrgent')}</span>
                        </label>
                    </div>
                    {isScribe && (
                        <div className="flex text-sm gap-2 items-center">
                            <Checkbox
                                id="enableTextModules"
                                className={`border-[2.3px] border-slate-200 rounded-[4px] ${formState.enableTextModules ? 'border-0' : 'bg-white'}`}
                                checked={formState.enableTextModules}
                                onCheckedChange={(checked) => setFormState((prev) => ({ ...prev, enableTextModules: checked }))}
                            />
                            <label htmlFor="enableTextModules" className="font-medium text-[14px] flex cursor-pointer">
                                <span className="font-semibold font-SuisseIntlLight">{t('form.enableTextModules')}</span>
                            </label>
                        </div>
                    )}
                </div>
                <div className={`sm:flex mt-4`}>
                    <Button type='submit' disabled={loading} className='w-full'>
                        {loading ? 
                            <Loader/>
                        :
                            <span>{t('form.generate', { type: generation_name })}</span>

                        }
                    </Button>
                </div>
            </form>
            {uploadNew && (
                <RecordingPopUp onUploadNewRecording={UploadNewRecording} setUploadNew={setUploadNew} HandleReset={HandleResetState} />
            )}
        </div>
    );
}
